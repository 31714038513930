@mixin adf-pagination-theme($theme) {
  adf-pagination {
    &.adf-pagination {
      height: 32px;
      line-height: 24px;
      padding: 16px 0;

      .adf-pagination__block {
        flex: none;
        padding: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.25px;

        &.adf-pagination__range-block {
          padding: 0 24px;
        }
      }

      .adf-pagination__perpage-block {
        flex: auto;
        padding-right: 24px;
      }

      .adf-pagination__actualinfo-block {
        padding-right: 12px;

        .mat-menu-trigger {
          display: none;
        }
      }

      .adf-pagination__previous-button {
        margin: 0 12px 0 0;
      }

      .adf-pagination__next-button {
        margin: 0;
      }

      .adf-pagination__controls-block {
        padding-right: 24px;
      }
    }
  }
}
