@mixin ay11-theme() {
  .mat-icon-button,
  .mat-button,
  .mat-checkbox,
  .mat-raised-button {
    &:focus-visible {
      @include rounded-outline;
    }

    .mat-ripple-element {
      display: none;
    }

    &.cdk-keyboard-focused {
      .mat-button-focus-overlay {
        display: none;
      }

      .mat-checkbox-ripple {
        @include rounded-outline;

        height: 22px;
        width: 22px;
        left: calc(50% - 11px);
        top: calc(50% - 11px);
      }
    }
  }

  .adf-content-node-selector-content-list .adf-datatable-list .adf-datatable-selected.mat-icon > svg {
    fill: var(--theme-blue-button-color);
  }

  .mat-list-base .mat-list-option {
    .mat-list-item-content {
      @include rounded-border(transparent);
    }

    &:focus-visible {
      background-color: white;

      .mat-list-item-content {
        @include rounded-border;
      }
    }
  }

  .adf-search-user-button.mat-button,
  .app-search-button {
    line-height: 36px;

    @include rounded-border(transparent);

    &:focus-visible {
      outline: none;

      @include rounded-border;
    }
  }

  .mat-menu-content {
    .mat-menu-item {
      .mat-menu-ripple {
        display: none;
      }

      @include rounded-border(transparent);

      &:focus-visible {
        @include rounded-border;

        background-color: white;
      }

      &.cdk-keyboard-focused:not([disabled]) {
        background-color: white;
      }
    }
  }

  .adf-toolbar-title {
    padding: 2px;
  }

  .mat-option {
    &.cdk-keyboard-focused {
      .mat-option-ripple {
        @include rounded-outline;
      }
    }
  }

  .mat-slide-toggle {
    .mat-slide-toggle-thumb {
      width: 15px;
      height: 15px;
      transform: translate(20%, 50%);
      background-color: #484a4d;
    }

    .mat-slide-toggle-bar {
      border-radius: 15px;
      height: 20px;
    }

    .mat-slide-toggle-thumb-container {
      top: -5px;
    }

    .mat-slide-toggle-ripple {
      display: none;
    }

    &.cdk-keyboard-focused {
      .mat-slide-toggle-bar {
        outline: 2px solid var(--theme-blue-button-color);
      }
    }

    &.mat-primary.mat-checked:not(.mat-disabled) {
      .mat-slide-toggle-bar {
        background-color: var(--theme-blue-button-color);
      }

      .mat-slide-toggle-thumb {
        background-color: white;
      }
    }
  }

  .mat-stroked-button,
  .mat-flat-button {
    &.cdk-keyboard-focused {
      .mat-button-ripple.mat-ripple {
        outline: 2px solid var(--theme-blue-button-color);
        inset: -4px;
      }
    }
  }

  .mat-datetimepicker-calendar-body-active .mat-datetimepicker-calendar-body-cell-content {
    outline: 2px solid var(--theme-datetimepicker-cell-focus-border);
  }

  .mat-datetimepicker-calendar-body-active .mat-datetimepicker-calendar-body-cell-content:not(.mat-datetimepicker-calendar-body-selected) {
    background-color: var(--theme-datetimepicker-cell-background);
  }

  .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-selected {
    background-color: var(--theme-datetimepicker-selected-date-background);
  }

  .mat-datetimepicker-calendar-body-active .mat-datetimepicker-calendar-body-cell-content.mat-datetimepicker-calendar-body-selected {
    background-color: var(--theme-about-panel-border-color);
    color: var(--theme-datetimepicker-font-color);
  }

  .mat-expansion-panel .mat-expansion-panel-header {
    border: 2px solid transparent;
    box-sizing: border-box;

    .mat-button-base.mat-button {
      outline: none;

      @include rounded-border(transparent);

      &:focus-visible {
        @include rounded-border;

        background-color: white;
      }
    }

    &.cdk-keyboard-focused:not([aria-disabled='true']) {
      background-color: white;

      @include rounded-border;
    }
  }

  .adf-error-snackbar {
    background-color: #ba1b1b;

    .mat-simple-snackbar,
    .mat-simple-snackbar-action {
      color: white;
    }
  }

  .adf-info-snackbar {
    background-color: var(--theme-blue-button-color);

    .mat-simple-snackbar,
    .mat-simple-snackbar-action {
      color: white;
    }
  }

  .mat-button-base {
    &.adf-filter-button {
      margin-left: -2px;
    }
  }

  .adf-pagination {
    &__perpage-block,
    &__actualinfo-block {
      /* stylelint-disable-next-line no-descending-specificity */
      .mat-icon-button {
        left: 3px;

        &:focus-visible {
          outline: none;
        }

        &.cdk-keyboard-focused {
          .mat-icon {
            @include rounded-outline;
          }
        }
      }
    }
  }
}
