/* stylelint-disable no-invalid-position-at-import-rule */
@import 'material-icons/iconfont/material-icons.css';

/* You can add global styles to this file, and also import other style files */
@import '@fontsource/open-sans';


body,
html {
  height: 100%;
}

body {
  margin: 0;
}

@import '@syncfusion/ej2-base/styles/offline-theme/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-splitbuttons/styles/material.css';
@import '@syncfusion/ej2-pdfviewer/styles/material.css';
@import '@syncfusion/ej2-notifications/styles/material.css';
@import '@syncfusion/ej2-angular-grids/styles/material.css';
@import '@syncfusion/ej2-angular-layouts/styles/material.css';
@import '@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '@syncfusion/ej2-icons/styles/material.css';
@import '@syncfusion/ej2-angular-calendars/styles/material.css';
@import '@syncfusion/ej2-calendars/styles/material.css';

/* Apply Open Sans font to all Syncfusion components except icons */
.e-control:not(.e-icons),
.e-control [class^='e-']:not(.e-icons),
.e-control [class*=' e-']:not(.e-icons) {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif !important;
}
