@use '@angular/material' as mat;
@import '@alfresco/adf-core/theming';
@import 'custom-theme';
@import 'variables/variables';
@include custom-theme($custom-theme);

$contrast-gray: #646569;

.mat-toolbar {
  color: var(--theme-text-color, rgba(0, 0, 0, 0.54));
}

.adf-name-location-cell-location.adf-datatable-cell-value {
  color: $contrast-gray;
}

.mat-tab-list {
  .mat-tab-labels {
    .mat-tab-label {
      &.mat-tab-label-active {
        color: #212121;
      }
    }
  }
}

.mat-form-field-label-wrapper .mat-form-field-label,
.mat-tab-list .mat-tab-labels .mat-tab-label,
.mat-checkbox-label,
mat-toolbar.mat-toolbar.mat-toolbar-multiple-row,
mat-toolbar.mat-toolbar.mat-toolbar-single-row {
  color: $contrast-gray;
  opacity: 1;
}

.adf-upload-dialog {
  &__header,
  &__content {
    color: $contrast-gray;
  }
}

.adf-version-list {
  .adf-version-list-item {
    &-comment,
    &-date {
      color: $contrast-gray;
      opacity: 1;
    }
  }
}

.mat-chip.mat-standard-chip {
  background-color: #efefef;
  color: $contrast-gray;
}

.adf-property-field {
  .adf-textitem-edit-icon.mat-icon {
    color: $contrast-gray;
  }
}

.adf-property-field.adf-card-textitem-field:hover .adf-property-clear-value {
  color: $contrast-gray;
}

.adf-empty-content__icon {
  color: $contrast-gray;
}
