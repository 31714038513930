/* stylelint-disable scss/no-global-function-names */
@use '@angular/material' as mat;
@import 'mixins';
@import 'theme';

$foreground: map-get($custom-theme, foreground);

html,
body {
  @include flex-column;

  font-size: 14px;
  font-family: 'Open Sans', serif;
  color: mat.get-color-from-palette($foreground, text, 0.87);
  margin: 0;

  & > main {
    @include flex-column;
  }
}

[dir='rtl'] .mat-icon {
  transform: scale(-1, 1);
}

// todo: move this to corresponding component theme files
.adf-container-full-width {
  overflow: hidden;
}

app-root,
app-shell,
app-about,
adf-layout-container,
aca-search-results,
ng-component {
  @include flex-column;
}

@media screen and (max-width: 600px) {
  app-root,
  app-about,
  adf-layout-container,
  aca-search-results,
  ng-component {
    height: 100vh;
  }
}

.aca-sidebar {
  display: block;
  height: 100%;
  overflow-y: scroll;
  max-width: 350px;
  width: 350px;
}

.aca-page-title {
  cursor: default;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 33px;
  margin: 0;
  color: var(--adf-theme-foreground-text-color-087);
  width: 100%;
}
